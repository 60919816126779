import {
  Badge,
  Button,
  ConfirmationModalContent,
  ErrorContainer,
  LabelComponent,
  LoadingSpinner,
  ModalPopup,
  Stack
} from 'components';
import { TextAreaInputField } from 'components/inputField/textAreaInpuField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { useAppDispatch } from 'store/hooks';
import {
  deleteAddendum,
  getAddendums,
  getUsersImageNotes,
  patchEditAddendum,
  postCreateAddendum,
  putEditAddendum
} from 'store/clientProfile/clientProfileActions';
import { useParams } from 'react-router-dom';
import { Addendum as AddendumType } from 'store/clientProfile/clientProfileSlice';

import style from './addendumForm.module.scss';
import { LastSaved } from './lastSaved';

type InitialValues = {
  addendum: string;
  status: 'DRAFT' | 'FINAL';
};

type Props = {
  initialValues?: InitialValues;
  editInitial?: boolean;
  withLabel?: boolean;
  addendumId?: number | null;
  hideForm?: () => void;
};

const AddendumForm = ({
  initialValues,
  editInitial = true,
  withLabel = true,
  addendumId = null,
  hideForm
}: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [edit, setEdit] = useState(editInitial);
  const [id, setId] = useState(addendumId);
  const [lastSaved, setLastSaved] = useState<string | null>(null);
  const { noteId } = useParams();
  const dispatch = useAppDispatch();

  const clearForm = () => {
    setDeleteModalOpen(false);
    setEdit(false);
    setId(null);
    setLastSaved(null);
    hideForm?.();
  };

  const formik = useFormik({
    initialValues: initialValues ?? { addendum: '', status: 'DRAFT', id: null, lastSaved: null },
    validationSchema: Yup.object({
      addendum: Yup.string().nullable()
    }),
    onSubmit: async (values, formikHelpers) => {
      if (id) {
        if (values.status === 'FINAL') {
          await dispatch(
            patchEditAddendum({
              status: values.status,
              addendumId: id,
              content: values.addendum,
              noteId: noteId ?? ''
            })
          );
          await dispatch(getAddendums({ noteId: noteId ?? '' })).then((res: any) => {
            if (!res.error && res.payload.content.length > 0) {
              //TODO need to optimize
              res.payload.content.map((addendum: AddendumType) => {
                if (addendum.author.profileImageKey) {
                  dispatch(
                    getUsersImageNotes({
                      profileImageKey: addendum.author.profileImageKey,
                      noteId: addendum.id.toString(),
                      noteAddendums: true
                    })
                  );
                }
              });
            }
          });
          clearForm();
          formikHelpers.resetForm();
        } else {
          const response = await dispatch(
            putEditAddendum({
              addendumId: id,
              content: values.addendum,
              noteId: noteId ?? ''
            })
          );

          if (response.meta.requestStatus === 'fulfilled') {
            setLastSaved(new Date().toISOString());
          }
        }
      } else {
        const response = await dispatch(
          postCreateAddendum({ content: values.addendum, noteId: noteId ?? '' })
        );
        if (response.meta.requestStatus === 'fulfilled') {
          setLastSaved(new Date().toISOString());
          setId(response.payload.id);
        }
      }
      const channel = new BroadcastChannel('note-update');
      channel.postMessage({ message: 'EDIT_NOTE', data: { id: noteId, hasAddendums: true } });
      channel.close();
    }
  });

  const debouncedAutosave = useRef(
    debounce(async () => {
      await formik.submitForm();
    }, 1000)
  ).current;

  useEffect(() => {
    if (formik.dirty && formik.values.addendum.trim() !== '') {
      debouncedAutosave();
    }
  }, [formik.values.addendum]);

  const handleDeleteAddendum = async () => {
    if (id) {
      await dispatch(deleteAddendum({ addendumId: id, noteId: noteId ?? '' }));
      clearForm();
      formik.resetForm();
    }
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          debouncedAutosave.cancel();
          formik.setFieldValue('status', 'FINAL');
          formik.handleSubmit(e);
        }}
      >
        <div className={style.addendumWrapper}>
          <div>
            {withLabel && (
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                mb="4px"
              >
                <LabelComponent text="Addendum" mandatory />
                {id && <Badge text="Draft" type="warning" />}
              </Stack>
            )}
            <Stack height="100px">
              <TextAreaInputField
                placeholder="Type addendum here"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="addendum"
                value={formik.values.addendum}
                disabled={!edit}
              />
              <ErrorContainer
                errors={formik.errors}
                name="addendum"
                visible={formik.errors.addendum && formik.touched.addendum}
              />
            </Stack>
          </div>
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt="12px"
          >
            <Stack display="flex" flexDirection="row" alignItems="center" gap="8px">
              <span
                aria-disabled={!id}
                onClick={id ? () => setDeleteModalOpen(true) : undefined}
                className={clsx('material-icons', style.icon)}
              >
                delete
              </span>
              <span
                aria-disabled={edit}
                className={clsx('material-icons', style.icon)}
                onClick={edit ? undefined : () => setEdit(true)}
              >
                edit
              </span>
            </Stack>
            {edit && (
              <Stack display="flex" flexDirection="row" alignItems="center" gap="16px">
                <Stack>
                  {formik.isSubmitting && formik.values.status === 'DRAFT' ? (
                    <LoadingSpinner size={30} />
                  ) : (
                    <LastSaved lastSaved={lastSaved} />
                  )}
                </Stack>
                <Stack width="120px">
                  <Button text="Final" theme="primary" disabled={formik.isSubmitting || !id} />
                </Stack>
              </Stack>
            )}
          </Stack>
        </div>
      </form>
      <ModalPopup open={deleteModalOpen} handleClose={() => setDeleteModalOpen(false)}>
        <ConfirmationModalContent
          confirmButtonText="Yes, delete"
          rejectButtonText="No, return to editing"
          title="Are you sure you want to delete your addendum?"
          text=" "
          handleConfirm={handleDeleteAddendum}
          handleReject={() => setDeleteModalOpen(false)}
        />
      </ModalPopup>
    </>
  );
};

export default AddendumForm;
