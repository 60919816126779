import { Avatar } from '@mui/material';
import { LoadingSpinner, Stack } from 'components';
import dayjs from 'dayjs';
import { getLsUserRoleObject } from 'modules/networkTools/localStorageTokens';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getClientNote, getUsersImageNotes } from 'store/clientProfile/clientProfileActions';
import { getClient, getClientHistory } from 'store/clients/clientsActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { NoteEdit } from './noteEdit';
import { NoteHeader } from './noteHeader';
import style from './notePopup.module.scss';
import { NoteView } from './noteView';

const NotePopup = () => {
  const { noteId, clientId } = useParams();

  const { clientNotes } = useAppSelector((state) => state.clientProfile);
  const { loading: clientLoading, data: client } = useAppSelector((state) => state.clients.client);
  const dispatch = useAppDispatch();
  const { organizationId } = getLsUserRoleObject();
  const note = clientNotes[noteId ?? '']?.data;

  const getNote = async () => {
    if (!clientId || !organizationId || !noteId) {
      return;
    }

    await Promise.all([
      dispatch(
        getClientNote({
          clientId,
          organizationId,
          noteId
        })
      ),
      dispatch(getClient({ clientId, organizationId })),
      dispatch(getClientHistory({ clientId }))
    ]);
  };

  useEffect(() => {
    if (note?.author?.profileImageKey && clientId) {
      dispatch(
        getUsersImageNotes({
          profileImageKey: note.author.profileImageKey,
          noteId,
          notePopup: true
        })
      );
    }
  }, [note?.author?.profileImageKey, clientId]);

  useEffect(() => {
    getNote();
  }, []);

  if (clientLoading || clientNotes[noteId ?? '']?.loading || client == null || note == null) {
    return (
      <Stack width="100%" display="flex" flexDirection="row" justifyContent="center" mt="64px">
        <LoadingSpinner />
      </Stack>
    );
  }

  return (
    <Stack width="100%" pb="16px">
      <NoteHeader client={client} note={note} />
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        px="20px"
        mt="16px"
      >
        <Stack gap="4px" display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{
              width: 16,
              height: 16
            }}
            alt={note.author?.profileImageKey}
            src={note.author?.profileImageUrl}
          >
            <span className="material-icons-outlined">person</span>
          </Avatar>
          <p className={style.authorName}>{note.author?.name}</p>
        </Stack>
        <p className={style.date}>{dayjs(note?.createdAt).format('MM/DD/YYYY')}</p>
      </Stack>
      <Stack px="20px">
        {note.draft && clientId ? (
          <NoteEdit
            noteId={noteId}
            note={note}
            clientId={clientId}
            organizationId={organizationId}
            context="popup"
            inputOpen
          />
        ) : (
          <NoteView note={note} />
        )}
      </Stack>
    </Stack>
  );
};

export default NotePopup;
