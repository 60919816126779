// import { datadogRum } from '@datadog/browser-rum';
// import { getDatadogUserId } from 'modules/networkTools/localStorageTokens';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

const DatadogRum: React.FunctionComponent = ({}) => {
  const [searchParams] = useSearchParams();
  const aqa = searchParams.get('aqa');
  const isAqaMode = aqa === 'true';

  // const setupDatadog = () => {
  //   const applicationId = String(process.env.REACT_APP_DATADOG_APP_ID);
  //   const clientToken = String(process.env.REACT_APP_DATADOG_CLIENT_TOKEN);
  //   const env = String(process.env.REACT_APP_ENVIRONMENT);
  //
  //   datadogRum.init({
  //     applicationId: applicationId,
  //     clientToken: clientToken,
  //     site: 'datadoghq.com',
  //     env: env,
  //     sessionSampleRate: 100,
  //     sessionReplaySampleRate: 0,
  //     trackUserInteractions: true,
  //     trackResources: true,
  //     trackLongTasks: true,
  //     defaultPrivacyLevel: 'mask'
  //   });
  //   const datadogUserId = getDatadogUserId();
  //   if (datadogUserId) {
  //     datadogRum.setUser({
  //       id: datadogUserId,
  //       name: 'Impact user'
  //     });
  //   }
  // };
  React.useEffect(() => {
    // const datadogEnabled = getIsDatadogEnabled();
    if (!isAqaMode) {
      // setupDatadog();
    }
  }, []);

  return null;
};

export default DatadogRum;
