import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';

const FeatureFlagsDashboard: React.FunctionComponent = ({}) => {
  const props = useFlags();

  function createPropValueArrayWithDiv(obj: any) {
    const result = Object.entries(obj)
      .map(([key, value]) => {
        return `<div >${key}:<span style="color: ${
          value === true ? '#00844B' : 'red'
        };margin-left:5px; font-weight:bold">${value}</span></div>`;
      })
      .join('');

    return { __html: result };
  }
  const result = createPropValueArrayWithDiv(props);

  return (
    <div className="App">
      <header
        className="App-header"
        style={{
          color: 'gray',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          flexWrap: 'wrap',
          wordBreak: 'break-all',
          padding: '32px',
          textAlign: 'end'
        }}
      >
        <div>
          <div style={{ fontWeight: 'bold' }}>Current flags</div>
          <hr />
          <div dangerouslySetInnerHTML={result}></div>
        </div>
      </header>
    </div>
  );
};

export default FeatureFlagsDashboard;
