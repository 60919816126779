import { Dropdown, Stack, Title } from 'components';
import { FC, useEffect, useCallback, RefObject } from 'react';
import { getClientNotes, getUsersImageNotes } from 'store/clientProfile/clientProfileActions';
import { noteTypeData } from 'store/clients/clientsSlice';
import {
  resetClientNotes,
  deleteNoteInClientNotes,
  updateNoteInClientNotes
} from 'store/clientProfile/clientProfileSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ClientProfileContentItem } from 'store/clientProfile/clientProfileSlice';
import { getCurrentNote } from 'modules/networkTools/localStorageTokens';
import { useSearchParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';

import { Note } from './note';
import style from './notesList.module.scss';

type Props = {
  clientId: string;
  organizationId: number;
  containerRef: RefObject<HTMLDivElement>;
};

const NotesList: FC<Props> = ({ clientId, organizationId, containerRef }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const noteTypes = searchParams.get('noteTypes') ?? '';
  const dispatch = useAppDispatch();
  const { notesList, totalNumber, size, page, loading } = useAppSelector(
    (state) => state.clientProfile.clientProfileList
  );
  const currentNote = getCurrentNote();

  const onChange = (e: SelectChangeEvent<string | string[]>) => {
    setSearchParams((params) => {
      if (e.target.value.length < 1) {
        params.delete('noteTypes');
      } else {
        params.set(
          'noteTypes',
          Array.isArray(e.target.value) ? e.target.value.join(',') : e.target.value
        );
      }

      return params;
    });
  };

  const loadMoreNotes = useCallback(
    async (newSize: number, newPage: number, firstRender?: boolean) => {
      if (loading || (typeof totalNumber === 'number' && notesList.length >= totalNumber)) return;

      await dispatch(
        getClientNotes({
          clientId,
          organizationId,
          page: newPage,
          size: newSize,
          types: noteTypes,
          firstRender
        })
      ).then((res: any) => {
        if (!res.error && res.payload.data.content.length > 0) {
          res.payload.data.content.forEach((noteItem: ClientProfileContentItem) => {
            if (noteItem.author.profileImageKey) {
              dispatch(
                getUsersImageNotes({
                  profileImageKey: noteItem.author.profileImageKey,
                  userId: clientId,
                  noteId: noteItem.id.toString(),
                  notesList: true
                })
              );
            }
          });
        }
      });
    },
    [clientId, organizationId, loading, notesList.length, totalNumber, noteTypes]
  );

  useEffect(() => {
    loadMoreNotes(5, 0, true);

    const channel = new BroadcastChannel('note-update');
    channel.onmessage = (event) => {
      const updatedNote = event.data;
      if (updatedNote.message === 'DELETE_NOTE') {
        dispatch(deleteNoteInClientNotes(updatedNote.noteId));
      }
      if (updatedNote.message === 'EDIT_NOTE') {
        dispatch(updateNoteInClientNotes(updatedNote.data));
      }
    };

    return () => {
      dispatch(resetClientNotes());
      channel.close();
    };
  }, [noteTypes]);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (
        container &&
        container.scrollTop + container.clientHeight >= container.scrollHeight - 76 &&
        !loading
      ) {
        loadMoreNotes(5, page + 1);
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, loadMoreNotes, size, loading]);

  return (
    <div className={style.notesList} data-test-id="profile-notes-list">
      <div className={style.notesHeader}>
        <Title text="History" type="h3" />
        <Stack width="140px">
          <Dropdown
            multiSelect
            value={noteTypes.split(',').filter(Boolean)}
            data={noteTypeData}
            onChange={onChange}
            label="All Notes"
            rounded
          />
        </Stack>
      </div>
      {notesList?.map((item) => (
        <button
          key={item.id}
          style={{ width: '100%' }}
          type={currentNote.currentNoteID === item.id ? 'reset' : 'button'}
          form="noteEdit"
        >
          <Note note={item} />
        </button>
      ))}
    </div>
  );
};

export default NotesList;
