import clsx from 'clsx';
import { Button, LoadingSpinner, Title } from 'components';
import { getCurrentNote, removeCurrentNote } from 'modules/networkTools/localStorageTokens';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { useSetClientImage } from 'store/clientProfile/clientUtils';
import { getClientProfileImg, postUploadProfileImg } from 'store/clients/clientsActions';
import { setNewClientPhoto } from 'store/clients/clientsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { notifyUserError, notifyUserSuccess } from 'utils/notifications';

import { NoteEdit } from './notePopup/noteEdit';
import style from './notes.module.scss';
import { NotesList } from './notesList';

type Props = {
  clientId: string;
  organizationId: number;
};

const NotesSection: FC<Props> = ({ clientId, organizationId }) => {
  const [inputOpen, setInputOpen] = useState(false);
  const noteId = getCurrentNote().currentNoteID;
  const { clientNotes } = useAppSelector((state) => state.clientProfile);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const note = clientNotes[noteId ?? '']?.data ?? undefined;
  const currentNote = getCurrentNote();
  const isSameClient = clientId === currentNote.clientId;
  const clientImageUrl = useSetClientImage(clientId);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    const file = e?.target?.files[0];
    console.log('file: ', file);

    const extension = file?.name?.split('.').pop();

    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    if (allowedExtensions.indexOf(extension) < 0) {
      notifyUserError('Invalid file type. File type must be ' + allowedExtensions.join(' '));
      if (inputRef.current) {
        inputRef.current.value = '';
      }

      return;
    }
    if (file?.size >= 3145728) {
      notifyUserError('Invalid file size. File size must be 3Mb or less');
      if (inputRef.current) {
        inputRef.current.value = '';
      }

      return;
    }
    if (file) {
      dispatch(
        postUploadProfileImg({
          clientId,
          file: file
        })
      )
        .then(() =>
          dispatch(
            getClientProfileImg({
              fileName: file?.name,
              clientId
            })
          )
        )
        .then(() =>
          dispatch(
            setNewClientPhoto({
              profileImageKey: file?.name
            })
          )
        );
    }
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  return (
    <div className={style.inputContainer} ref={containerRef}>
      <div className={style.notesPhoto} data-test-id="profile-notes-photo">
        <div className={style.uploadImg}>
          <Button
            size="extraSmall"
            type="button"
            theme={'transparent'}
            icon={<span className={clsx('material-icons', style.uploadImg)}>photo_camera</span>}
            handleOnClick={handleUploadClick}
          />
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>

        {clientImageUrl && (
          <img key={clientImageUrl} src={clientImageUrl} className={style.clientImageBgr} />
        )}
        {!clientImageUrl && (
          <div className={style.spinner}>
            {' '}
            <LoadingSpinner />
          </div>
        )}
      </div>
      <div className={style.notesHeader} data-test-id="profile-notes-add">
        <Title text="Notes" type="h3" />
        <Button
          type="reset"
          form="noteEdit"
          customStyleClass={style.icon}
          icon={<span className={'material-icons'}>add</span>}
          theme="transparent"
          handleOnClick={() => {
            if (currentNote.currentNoteID && isSameClient) {
              notifyUserSuccess('Your draft was automatically saved.');
            }
            removeCurrentNote();
            setInputOpen(true);
          }}
        />
      </div>
      <div className={style.notesBody}>
        <div className={style.candidateInputGroup}>
          <div className={style.blueAccordionBox} onClick={() => setInputOpen(!inputOpen)}>
            <span className={'material-icons'}>{`${
              !inputOpen ? 'expand_more' : 'expand_less'
            }`}</span>
          </div>

          <NoteEdit
            note={isSameClient ? note : undefined}
            clientId={clientId}
            organizationId={organizationId}
            inputOpen={inputOpen}
            noteId={isSameClient ? currentNote.currentNoteID : undefined}
            context="noteSection"
          />
          <NotesList
            clientId={clientId}
            organizationId={organizationId}
            containerRef={containerRef}
          />
        </div>
      </div>
    </div>
  );
};

export default NotesSection;
